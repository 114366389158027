<template>
  <div id="app">
    <v-app class="transparent_bg">
      <div class="list-table">
        <v-container grid-list-xl>
          <v-snackbar v-model="showSnackbar" :timeout="5000">
            {{ snackbarText }}
            <v-btn color="primary" text @click="showSnackbar = false">
              Close
            </v-btn>
          </v-snackbar>

          <v-layout row wrap>
            <v-flex lg12>
              <v-card>
                <v-toolbar card color="white">
                  <v-text-field
                    flat
                    solo
                    v-model="search"
                    prepend-icon="search"
                    placeholder="Filtrar"
                    hide-details
                    class="hidden-sm-and-down"
                  ></v-text-field>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="complex.headers"
                    :items="complex.items"
                    must-sort
                    :pagination.sync="query"
                    :rows-per-page-items="[1000, 2000, 5000]"
                    class="elevation-0"
                    item-key="_id"
                  >
                    <template
                      slot="items"
                      slot-scope="props"
                      class="justify-center"
                    >
                      <td>{{ props.item.name }}</td>
                      <td>{{ props.item.ambient }}</td>
                      <td>{{ props.item.createdAt | moment('DD/MM/YYYY') }}</td>
                      <td>
                        {{ props.item.group ? props.item.group.name : '' }}
                      </td>
                      <td>
                        <Tag :status="props.item.status" />
                      </td>

                      <td>
                        <v-layout align-center justify-center row>
                          <v-btn
                            dark
                            color="primary"
                            small
                            @click="_openProject(props.item)"
                          >
                            Abrir
                          </v-btn>

                          <v-btn
                            v-if="_isProjectDelivered(props.item)"
                            dark
                            color="secondary"
                            small
                            @click="_archive(props.item)"
                          >
                            Arquivar
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-menu class="right">
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>

                            <v-list>
                              <v-list-tile>
                                <v-list-tile-title
                                  @click="_showDeleteConfirmation(props.item)"
                                  >Remover</v-list-tile-title
                                >
                              </v-list-tile>
                              <v-list-tile v-if="!props.item.group">
                                <v-list-tile-title
                                  @click="_showGroupDialog(props.item)"
                                  >Agrupar</v-list-tile-title
                                >
                              </v-list-tile>
                              <v-list-tile v-if="props.item.group">
                                <v-list-tile-title
                                  @click="_showUngroupConfirmation(props.item)"
                                  >Desagrupar</v-list-tile-title
                                >
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </v-layout>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <confirmation-dialog
        :show.sync="showDeleteConfirmation"
        :confirmationAction="_delete"
        :dismissAction="() => (this.showDeleteConfirmation = false)"
        :loading="loading"
        message="Tem certeza que deseja excluir este projeto?"
      />
      <confirmation-dialog
        :show.sync="showUngroupConfirmation"
        :confirmationAction="_ungroup"
        :dismissAction="() => (this.showDeleteConfirmation = false)"
        :loading="loading"
        message="Tem certeza que deseja remover este projeto do seu grupo?"
      />
      <group-dialog ref="groupDialog" :on-save="_fetchProjects" />
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Tag from '@/components/project/Tag';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog';
import constants from '@/utils/constants';
import GroupDialog from '@/components/dialogs/GroupDialog';

export default {
  components: { GroupDialog, Tag, ConfirmationDialog },
  data() {
    return {
      showDeleteConfirmation: false,
      showUngroupConfirmation: false,
      loading: false,
      showSnackbar: false,
      snackbarText: '',
      currentUser: null,
      projectToDelete: null,
      projectToUngroup: null,
      query: {
        descending: true,
        sortBy: 'createdAt',
        page: 1,
        rowsPerPage: 1000,
        totalItems: 0
      },
      search: '',
      complex: {
        headers: [
          {
            text: 'Nome',
            value: 'name'
          },
          {
            text: 'Ambiente',
            value: 'ambient'
          },
          {
            text: 'Data',
            value: 'createdAt'
          },
          {
            text: 'Grupo',
            value: 'group.name'
          },
          {
            text: 'Status',
            value: 'status'
          },

          {
            text: '',
            value: '',
            sortable: false
          }
        ],
        items: [],
        total: 0
      }
    };
  },
  computed: {
    ...mapGetters(['getProjects', 'getUser', 'getTotal'])
  },

  created() {
    this.currentUser = this.getUser;
  },

  methods: {
    ...mapActions({
      _listProjects: 'listProjects',
      _archiveProject: 'archiveProject',
      _deleteProject: 'deleteProject',
      _handleVersion: 'handleVersion',
      _removeGroup: 'removeGroup'
    }),

    _searchProjects(query) {
      console.log(query);
    },

    _nextPage(query) {
      this._listProjects(query);
      this.complex.items = this.getProjects;
    },

    async _fetchProjects() {
      await this._listProjects(this.query);
      this.complex.items = this.getProjects;
      this.complex.total = this.getTotal;
      this.query.totalItems = this.complex.total;
    },

    _openProject(project) {
      this.$router.push(`/project/${project._id}`);
    },
    async _archive(project) {
      await this._archiveProject(project._id);
      await this._listProjects(this.query);
      this.complex.items = this.getProjects;
    },
    async _delete() {
      this.loading = true;
      this.showDeleteConfirmation = false;
      await this._deleteProject(this.projectToDelete._id);
      await this._listProjects(this.query);
      this.complex.items = this.getProjects;
      this.loading = false;
    },
    _isProjectDelivered(project) {
      return project.status === constants.STATUS_DELIVERED;
    },
    _showDeleteConfirmation(project) {
      this.projectToDelete = project;
      this.showDeleteConfirmation = true;
    },
    _showUngroupConfirmation(project) {
      this.projectToUngroup = project;
      this.showUngroupConfirmation = true;
    },
    _showGroupDialog(project) {
      this.$refs.groupDialog.open(project, this.getProjects);
    },
    async _ungroup() {
      try {
        await this._removeGroup(this.projectToUngroup._id);
        this.showUngroupConfirmation = false;
        await this._fetchProjects();
      } catch (e) {
        alert('Algo deu errado. Por favor, tente novamente mais tarde.');
      }
    }
  },
  watch: {
    query(newValue) {}
  },
  async mounted() {
    this.currentUser = this.getUser;
    await this._handleVersion();
    try {
      await this._listProjects(this.query);
    } catch (e) {
      console.log(e);
    }
    this.complex.items = this.getProjects;
    this.complex.total = this.getTotal;
    this.query.totalItems = this.complex.total;
  }
};
</script>

<style lang="css" scoped></style>
